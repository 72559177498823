import {Action, applyMiddleware, combineReducers, Store, createStore} from "redux";
import thunk, {ThunkAction, ThunkDispatch, ThunkMiddleware} from "redux-thunk";
import deals from "../corelogic/reducer/deals";
import {Dependencies} from "./dependencies";
import {AppState} from "./appState.interface";
import {composeWithDevTools} from "redux-devtools-extension";

export const configureStore = (dependencies: Dependencies) =>
    createStore(
        combineReducers({
            deals
        }), composeWithDevTools(applyMiddleware(dependencies ?
            thunk.withExtraArgument({
                ...dependencies
            }) as ThunkMiddleware<AppState, Action, Dependencies> :
            thunk)));

export type ReduxStore = Store<AppState> & { dispatch: ThunkDispatch<AppState, Dependencies, Action> }

export type ThunkResult<R> = ThunkAction<R, AppState, Dependencies, Action>;
