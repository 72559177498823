import React from "react"
import { Provider } from "react-redux"
import {configureStore} from "./src/redux/configureStore";
import {HttpGatewayApiGateway} from "./src/adapters/secondary/gateways/httpApiGateay"

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = configureStore({ApiGateway: new HttpGatewayApiGateway()})
  return <Provider store={store}>{element}</Provider>
}