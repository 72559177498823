import {ApiGateway} from "../../../corelogic/gateways/apiGateway";
import {Deal} from "../../../corelogic/models/deal.interface"; 
import {parse, format} from 'date-fns'
import {DealsApi, Configuration} from "idealhotel-client";

const config = new Configuration({ basePath: process.env.GATSBY_API_HOST })

export class HttpGatewayApiGateway implements ApiGateway {

    private _deals: Deal[] = [];


    async retrieveDeals(): Promise<Deal[]> {
        const client = new DealsApi(config)
        try {
            const [deals, _] = await Promise.all([
                client.fetchDealsDealsDateGet(format(new Date(), 'yyyy-MM-dd')),
                new Promise(r => setTimeout(r, 1000))
            ])
            return deals.data.map((deal: any) => ({
                key: deal.key,
                title: deal.title,
                subtitle: deal.subtitle,
                newOriginKey: deal.newOriginKey,
                descriptionHTML: deal.descriptionHTML,
                offerHTML: deal.offerHTML,
                dateFrom: parse(deal.dateFrom, 'yyyy-MM-dd', new Date()),
                dateTo: parse(deal.dateTo, 'yyyy-MM-dd', new Date()),
                dateDescription: deal.dateDescription,
                origin: deal.offerOrigin,
                offerLink: deal.offerLink,
                hotelName: deal.hotelName,
                hotelPicture: deal.hotelPicture,
                hotelDescription: deal.hotelDescription,
                contactEmail: deal.contactEmail,
                contactPhone: deal.contactPhone,
                contactWebsite: deal.contactWebsite,
                expired: deal.expired
            }))
        } catch (error) {
            return []
        }
        
    }


    feedWithDeals(...deals: Deal[]){
        this._deals = deals;
    }

  
}
